#home {
    .split-container {
        display: flex;
        height: calc(
            100vh - 150px
        ); /* Adjust height based on header and navbar height */
        margin-top: 20px;
    }
    .left-section,
    .right-section {
        flex: 1;
        padding: 20px;
        border: 1px solid #dee2e6;
        background-color: #ffffff;
        margin: 10px;
        height: 100vh;
    }
    .list-group-item {
        cursor: pointer;
    }
}
.card {
    flex: 1;
    padding: 20px;
    border: 1px solid #dee2e6;
    background-color: #ffffff;
    margin: 10px;
}
.custom-link {
    color: black;
    text-decoration: none;
    font-weight: bold;
}
